import React from 'react';
import { bool } from 'prop-types';

import { basketProps } from './propTypes';
import { Desktop } from './Desktop';
import { Mobile } from './Mobile';
import { TBYBTotalCaption } from './TBYBTotalCaption';

const Basket = ({
  addOns,
  discountedTotalPrice,
  services,
  totalPrice,
  isMobile = false,
}) => {
  const renderTotalCaption = () => (
    <TBYBTotalCaption
      services={services}
      discountedTotalPrice={discountedTotalPrice}
      totalPrice={totalPrice}
    />
  );
  if (isMobile) {
    return (
      <Mobile
        addOns={addOns}
        discountedTotalPrice={discountedTotalPrice}
        services={services}
        totalPrice={totalPrice}
        totalText={renderTotalCaption()}
      />
    );
  }

  return (
    <>
      <Desktop
        addOns={addOns}
        discountedTotalPrice={discountedTotalPrice}
        services={services}
        totalPrice={totalPrice}
        totalText={renderTotalCaption()}
      />
    </>
  );
};

Basket.propTypes = {
  ...basketProps,
  isMobile: bool,
};

export { Basket };
